import { Button, Checkbox, Label, TextInput } from 'flowbite-react';
import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useRegisterMutation } from '../../api/api_index';
import { useTypedDispatch } from '../../redux/hooks';
import { setUser } from '../../redux/auth/slice';
import { toast } from 'react-toastify';
import trackEvent, { MetricEventType } from '../../utils/trackEvent';
import ThirdPartyAuthWidget from '../../components/auth/ThirdPartyAuthWidget';
import PhoneInput from 'react-phone-number-input';
import 'react-phone-number-input/style.css';

function SignUpView() {
  const [phone, setPhone] = useState<string | undefined>('');
  const [email, setEmail] = useState('');
  const [pass, setPass] = useState('');
  const [name, setName] = useState('');
  const [pass1, setPass1] = useState('');
  const [remember, setRemember] = useState(false);
  const dispatch = useTypedDispatch();
  const navigate = useNavigate();
  const [register] = useRegisterMutation();

  //track start session event
  useEffect(() => {
    trackEvent(MetricEventType.REG_START);
  }, []);

  async function handleSubmit() {
    trackEvent(MetricEventType.REG_BUTTON);
    if (pass !== pass1) {
      trackEvent(MetricEventType.REG_ERROR);
      return toast.error('Подтверждение не совпадает с паролем', {
        position: 'bottom-right',
      });
    }

    try {
      const res = await register({
        email,
        name,
        pass,
        remember,
        phone,
      }).unwrap();
      console.log(res);
      dispatch(
        setUser({
          name: res.user.name as string,
          username: res.user.email,
          uid: res.user.id,
        }),
      );
      await sessionStorage.setItem('access_token', res.access_token);
      trackEvent(MetricEventType.REG_SUCCESS);
      navigate('/account/unit-economy');
    } catch (e) {
      trackEvent(MetricEventType.REG_ERROR);
      console.log(e);
    }
  }

  return (
    <section className="bg-gray-50 dark:bg-gray-900 flex flex-row">
      <div className="flex flex-col items-center justify-center px-6 py-8 mx-auto md:h-screen lg:py-0 min-w-[400px]">
        <Link
          to="/"
          className="flex items-center text-2xl font-semibold text-gray-900 dark:text-white">
          <img className="w-32 h-32 object-contain" src="logo.jpg" alt="logo" />
        </Link>
        <div className="w-full bg-white rounded-xl shadow-md dark:border md:mt-0 sm:max-w-md xl:p-0 dark:bg-gray-800 dark:border-gray-700">
          <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
            <h1 className="text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl dark:text-white text-center">
              Регистрация
            </h1>
            {/*<Alert color="success">
              Бесплатный доступ ко всем функциям на{' '}
              <span className="font-medium">90 дней</span>!
            </Alert>*/}
            <form className="flex max-w-md flex-col gap-4">
              <div>
                <div className="mb-2 block">
                  <Label htmlFor="name" value="Ваше имя" />
                </div>
                <TextInput
                  id="name"
                  placeholder="Иван Иванов"
                  required
                  type="text"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
              </div>
              <div>
                <div className="mb-2 block">
                  <Label htmlFor="email1" value="Ваш email" />
                </div>
                <TextInput
                  id="email1"
                  placeholder="Email"
                  required
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>
              <div>
                <div className="mb-2 block">
                  <Label htmlFor="phone" value="Ваш телефон" />
                </div>
                <PhoneInput
                  id={'phone'}
                  international
                  defaultCountry="RU"
                  value={phone}
                  onChange={(val) => setPhone(val)}
                />
              </div>
              <div>
                <div className="mb-2 block">
                  <Label htmlFor="password1" value="Пароль" />
                </div>
                <TextInput
                  id="password1"
                  required
                  type="password"
                  value={pass}
                  onChange={(e) => setPass(e.target.value)}
                />
              </div>
              <div>
                <div className="mb-2 block">
                  <Label htmlFor="password1" value="Подтвердите пароль" />
                </div>
                <TextInput
                  id="password2"
                  required
                  type="password"
                  value={pass1}
                  onChange={(e) => setPass1(e.target.value)}
                />
              </div>
              <div className="flex items-center gap-2">
                <Checkbox
                  id="remember"
                  checked={remember}
                  onChange={(e) => setRemember(e.target.checked)}
                />
                <Label htmlFor="remember">Запомнить меня</Label>
              </div>
              <Button
                type="submit"
                className="bg-primary-600"
                onClick={(e) => {
                  e.preventDefault();
                  handleSubmit();
                }}>
                Зарегистрироваться
              </Button>
              <p className="text-sm font-light text-gray-500 dark:text-gray-400">
                Уже есть аккаунт?{' '}
                <Link
                  to="/signin"
                  className="font-medium text-primary-600 hover:underline dark:text-primary-500">
                  Войти
                </Link>
              </p>
            </form>

            <ThirdPartyAuthWidget />
          </div>
        </div>
      </div>
      <div className={'flex bg-white'}>
        <img src={'sign-up-cover.jpg'} className={'object-contain'} />
      </div>
    </section>
  );
}

export default SignUpView;
